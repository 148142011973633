/* eslint-disable */
import { useEffect, useState } from "react";
import DropdownLanguages from "../DropdownLanguages";
import LocationSearchInput from "../PlacesAutocomplete";
import { Cut } from "../../generated/types";
import CutOptions from "./CutOptions";
import BaseLayersOptions from "./BaseLayersOptions";
import { useTranslation } from "react-i18next";
import OverlaysOptions from "./OverlaysOptions";
import "./MapTool.scss";
import "./ScoreCard.scss";
import ScoreCardContainer from "./ScoreCardContainer";
import { ConstructGeoMapping, GetGeoMapping } from "./Helpers/GeoMapping";
import Loading from "../loading";
import LoadingLayover from "../loadinglayover";
import { GeographiesCache } from "./GeographiesCache";
import MapBoxMap from "./MapBoxMap";
import MapInfoBox from "./MapInfoBox/MapInfoBox";
import MapInfo from "./MapInfo/MapInfo";
import getBaselayerData from "../../api/baselayers/getBaselayerData";
import getPlaceCensusTract from "../../api/map/getPlaceCensusTract";
import { determineGeo } from "./utils";
import { CURRENT_YEAR } from "../../constants";
import CtMapping from "../../lib/ctmapping.json";
import censusGeoJson from "../../lib/maps/geojson/census_tracts.json";

type SelectedGeo = {
	readonly geoId: string;
	readonly cut: Cut;
	readonly isPlaceId?: boolean;
	readonly coordinates?: any[];
	isScoreLoaded: boolean;
};
type MapViewLevel = {
	viewLevel: number;
};

type PlaceMarker = {
	latitude: number;
	longitude: number;
};

const MapTool = (props: any) => {
	const { t } = useTranslation();
	const [selectedCutOptions, setSelectedCutOptions] = useState({
		address: null,
		city: null,
		zip: null,
		census_tract: "ALL",
	});
	const [selectedBaseLayer, setSelectedBaseLayer] = useState(null);
	const [selectedQuartile, setSelectedQuartile] = useState(null);
	const [selectedOverlay, setSelectedOverlay] = useState(null);
	const [selectedCutGeo, setSelectedCutGeo] = useState<SelectedGeo | null>(
		null
	);
	const [selectedGeo, setSelectedGeo] = useState<SelectedGeo | null>(null);
	const [selectMapViewLevel, setSelectMapViewLevel] =
		useState<MapViewLevel | null>(null);

	const [placeMarker, setPlaceMarker] = useState<PlaceMarker | null>(null);
	const [mapCut, setMapCut] = useState<Cut>(Cut.CensusTract);
	const [scorecardView, setScorecardView] = useState({
		partial: false,
		printed: false,
	});
	const [showSearchClear, setShowSearchClear] = useState(false);
	const [highlightedGeos, setHighlightedGeos] = useState([]);
	const [dataLoading, setDataLoading] = useState(false);

	const [reset, setReset] = useState(false);

	useEffect(() => {
		ConstructGeoMapping();
	}, []);

	const blackListedCities = [
		// "ALISO VIEJO",
		// "BREA",
		// "CYPRESS",
		// "DANA POINT",
		// "FOUNTAIN VALLEY",
		// "LA HABRA",
		// "LA PALMA",
		// "LAGUNA BEACH",
		// "LAGUNA HILLS",
		// "LAGUNA NIGUEL",
		// "LAGUNA WOODS",
		// "LOS ALAMITOS",
		// "PLACENTIA",
		// "RANCHO SANTA MARGARITA",
		// "SAN CLEMENTE",
		// "SAN JUAN CAPISTRANO",
		// "SEAL BEACH",
		// "STANTON",
		// "VILLA PARK",
	];

	const cutOptionChanged = (id: string, type: string) => {
		setScorecardView({ partial: false, printed: false });
		setSelectedGeo(null);
		// setPlaceMarker(null);
		setSelectedQuartile(null);
		if (id == null) {
			setSelectedCutOptions({
				address: null,
				city: null,
				zip: null,
				census_tract: "ALL",
			});
			setHighlightedGeos([]);
			setSelectedCutGeo(null);
		} else {
			let mappings = [];
			if (id === "ALL") {
				//if city view is selected to 'ALL' , then all city view should  shown
				if (type == "CITY") {
					setHighlightedGeos([id]);
				} else {
					mappings = GetGeoMapping(id, type)?.census_tracts || [];
					setHighlightedGeos(mappings);
				}
			} else {
				mappings.push(id);
				setHighlightedGeos(mappings);
			}
			//  setHighlightedGeos(mappings);
			setSelectedCutGeo({
				geoId: id,
				cut: type as Cut,
				isScoreLoaded: false,
			});

			setShowSearchClear(false);
			if (type == Cut.City) {
				setSelectedCutOptions({
					address: selectedCutOptions.address,
					city: id,
					zip: null,
					census_tract: null,
				});
				setMapCut(Cut.City);
			} else if (type == Cut.ZipCode) {
				setSelectedCutOptions({
					address: selectedCutOptions.address,
					city: null,
					zip: id,
					census_tract: null,
				});
				setMapCut(Cut.ZipCode);
			} else if (type == Cut.CensusTract) {
				setSelectedCutOptions({
					address: selectedCutOptions.address,
					city: null,
					zip: null,
					census_tract: id,
				});
				setMapCut(Cut.CensusTract);
			}
		}
	};

	interface Feature {
		type: string;
		geometry: {
			type: string;
			coordinates: number[][][];
		};
		properties: {
			geoid: string;
		};
	}

	function filterFeaturesByGeoids(
		features: Feature[],
		geoids: string[]
	): Feature[] {
		return features.filter((feature) =>
			geoids.includes(feature.properties.geoid)
		);
	}

	// Define the structure of the individual feature
	interface GeoFeature {
		type: string;
		geometry: {
			type: string;
			coordinates: any[]; // Use `any[]` if coordinates are not strictly defined
		};
	}
	const getCity = (location: string) => {
		const arr = location.split(", ");
		if (arr.length === 4) {
			return arr[1]?.toUpperCase() || "ALL";
		}
		return "ALL";
	};

	const placeChanged = async (value: string, placeId: string) => {
		setSelectedCutOptions({
			address: value,
			city: null,
			zip: null,
			census_tract: null,
		});
		setSelectedGeo(null);
		setPlaceMarker(null);
		try {
			setDataLoading(true);
			const { data } = await getPlaceCensusTract(placeId);
			if (data?.longitude && data?.latitude) {
				setPlaceMarker({
					longitude: data.longitude,
					latitude: data.latitude,
				});
			}
			if (data.census_tract != "") {
				setSelectedCutGeo({
					geoId: data.census_tract,
					cut: Cut.CensusTract,
					isPlaceId: true,
					isScoreLoaded: false,
				});
				let option = {
					address: null,
					city: null,
					zip: null,
					census_tract: null,
				};
				if (selectedCutOptions.city) {
					const city = getCity(value);
					if (blackListedCities.includes(city)) {
						setHighlightedGeos([]);
						option = {
							address: value,
							city: "ALL",
							zip: null,
							census_tract: null,
						};
						return;
					}
					if (city !== "ALL") {
						setHighlightedGeos([city]);
					} else {
						setHighlightedGeos([]);
					}
					option = {
						address: value,
						city: getCity(value),
						zip: null,
						census_tract: null,
					};
					setScorecardView({ partial: true, printed: false });
					setSelectedGeo({
						geoId: city,
						cut: Cut.City,
						isPlaceId: true,
						isScoreLoaded: false,
					});
				} else if (selectedCutOptions.zip) {
					setHighlightedGeos([]);
					option = {
						address: value,
						city: null,
						zip: "ALL",
						census_tract: null,
					};
					setScorecardView({ partial: false, printed: false });
				} else {
					setHighlightedGeos([data.census_tract]);
					option = {
						address: value,
						city: null,
						zip: null,
						census_tract: data.census_tract,
					};
					setScorecardView({ partial: true, printed: false });
					setSelectedGeo({
						geoId: data.census_tract,
						cut: Cut.CensusTract,
						isPlaceId: true,
						isScoreLoaded: false,
					});
				}
				setSelectedCutOptions(option);
			} else {
				console.error("census_tract is empty");
				setHighlightedGeos([]);
				setSelectedCutGeo(null);
				setSelectedCutOptions({
					address: value,
					city: null,
					zip: null,
					census_tract: null,
				});
				setScorecardView({ ...scorecardView, partial: true });
				setSelectedGeo({
					geoId: "0",
					cut: Cut.CensusTract,
					isPlaceId: true,
					isScoreLoaded: false,
				});
			}
			setDataLoading(false);
		} catch (e) {
			setDataLoading(false);
			console.error(e);
		}
	};

	const layerOptionChanged = async (layers: any[]) => {
		if (selectedGeo) {
			clearAllFilters();
		}
		setSelectedBaseLayer(layers.length == 0 ? null : layers[0]);
		if (selectedQuartile) {
			setHighlightedGeos([]);
		}
		setSelectedQuartile(null);
	};

	const overlayOptionChanged = (overlays: any[]) => {
		setSelectedOverlay(overlays.length == 0 ? null : overlays[0]);
	};

	const filterToggleClick = () => {
		let ft = document.getElementById("filterOptions");
		if (!ft.style.display || ft.style.display == "none") {
			ft.style.display = "block";
		} else {
			ft.style.display = "none";
		}
	};

	const clearInputClick = () => {
		setShowSearchClear(false);
		setPlaceMarker(null);
		setSelectedCutOptions({
			address: "",
			city: null,
			zip: null,
			census_tract: "ALL",
		});
		setSelectedCutGeo(null);
		setHighlightedGeos([]);
	};

	const mapClicked = (geo: SelectedGeo) => {
		if (selectedGeo && selectedGeo.geoId == geo.geoId) {
			return;
		}
		if (blackListedCities.includes(geo.geoId.toUpperCase())) return;
		geo.isScoreLoaded = false;
		if (selectedGeo) {
			setSelectedGeo(null);
			setTimeout(() => {
				setSelectedGeo(geo);
				setScorecardView({ partial: true, printed: false });
			}, 200);
		} else {
			setSelectedGeo(geo);
			setScorecardView({ partial: true, printed: false });
		}

		if (!selectedCutGeo || selectedCutOptions.census_tract != null) {
			setHighlightedGeos([geo.geoId]);
			setSelectedCutGeo(geo);
			if (geo.cut === "CITY") {
				setSelectedCutOptions({
					address: null,
					city: geo.geoId,
					zip: null,
					census_tract: null,
				});
			} else if (geo.cut === "ZIP_CODE") {
				setSelectedCutOptions({
					address: null,
					city: null,
					zip: geo.geoId,
					census_tract: null,
				});
			} else {
				setSelectedCutOptions({
					address: selectedCutOptions.address,
					city: null,
					zip: null,
					census_tract: geo.geoId,
				});
			}
		} else if (
			selectedCutGeo &&
			(selectedCutOptions.city != null || selectedCutOptions.zip != null)
		) {
			let found = false;
			for (let a = 0; a < highlightedGeos?.length; a++) {
				if (highlightedGeos[a] == geo.geoId) {
					found = true;
					break;
				}
			}
			if (!found) {
				setHighlightedGeos([geo.geoId]);
				setSelectedCutGeo(null);
				setSelectedQuartile(null);
			}
			if (geo.geoId != "ALL") {
				setHighlightedGeos([geo.geoId]);
				setSelectedCutGeo(geo);
				setSelectedCutOptions({
					address: null,
					city: geo.cut == "CITY" ? geo.geoId : null,
					zip: geo.cut == "ZIP_CODE" ? geo.geoId : null,
					census_tract: geo.cut == "CENSUS_TRACT" ? geo.geoId : null,
				});
				setSelectMapViewLevel({ viewLevel: 1 });
			}
		}
	};

	const closeScoreCardClick = () => {
		setSelectedGeo(null);
		setScorecardView({ partial: false, printed: false });
		//setShowSearchClear(false);
		if (!selectedCutGeo) {
			setHighlightedGeos([]);
		}
		closeScoreCardClickReverse();
	};

	const closeScoreCardClickReverse = () => {
		if (selectedGeo.cut == "CITY") {
		}
		// setSelectedCutOptions({
		//   address: null,
		//   city: geo.geoId,
		//   zip: null,
		//   census_tract: null,
		// });
	};

	const similarLabelClick = (value: string) => {
		cutOptionChanged(value, selectedGeo.cut);
		let val = {
			geoId: value,
			cut: selectedGeo.cut,
			isPlaceId: false,
			isScoreLoaded: false,
		};
		setTimeout(() => {
			setSelectedGeo(val);
			setScorecardView({ partial: true, printed: false });
		}, 200);
	};

	const summaryActionClick = () => {
		setScorecardView({ partial: true, printed: false });
		setSelectedGeo(selectedCutGeo);
	};

	const scorecardActionClick = () => {
		setScorecardView({ partial: false, printed: false });
		setSelectedGeo(selectedCutGeo);
	};

	const clearCutOptions = (type: string) => {
		// if (selectedCutOptions?.city) {
		// 	setSelectedCutOptions({
		// 		address: null,
		// 		city: "ALL",
		// 		zip: null,
		// 		census_tract: null,
		// 	});

		// 	setSelectedCutGeo({
		// 		geoId: "ALL",
		// 		cut: Cut.City,
		// 		isScoreLoaded: false,
		// 	});
		// } else if (selectedCutOptions?.zip) {
		// 	setSelectedCutOptions({
		// 		address: null,
		// 		city: null,
		// 		zip: "ALL",
		// 		census_tract: null,
		// 	});
		// 	setSelectedCutGeo({
		// 		geoId: "ALL",
		// 		cut: Cut.ZipCode,
		// 		isScoreLoaded: false,
		// 	});
		// } else {
		// 	setSelectedCutOptions({
		// 		address: null,
		// 		city: null,
		// 		zip: null,
		// 		census_tract: "ALL",
		// 	});

		// 	setSelectedCutGeo({
		// 		geoId: "ALL",
		// 		cut: Cut.CensusTract,
		// 		isScoreLoaded: false,
		// 	});
		// 	setHighlightedGeos([]);
		// }
		setSelectedCutGeo({
			geoId: "ALL",
			cut: Cut.CensusTract,
			isScoreLoaded: false,
		});

		setTimeout(() => {
			setSelectedCutOptions({
				address: null,
				city: null,
				zip: null,
				census_tract: "ALL",
			});
		}, 100);

		setShowSearchClear(false);
		setPlaceMarker(null);
		setTimeout(() => {
			setHighlightedGeos([]);
		}, 300);
	};

	const clearAllFilters = () => {
		clearCutOptions("");
		setSelectedBaseLayer(null);
		setSelectedQuartile(null);
		setSelectedGeo(null);
		setSelectedOverlay({});
		setReset(true);
		setSelectedCutOptions({
			address: null,
			city: null,
			zip: null,
			census_tract: "ALL",
		});
		setMapCut(Cut.CensusTract);
		setTimeout(() => {
			setSelectedOverlay(null);
		}, 1);
	};

	const quartileOptionChanged = async (quartile: any) => {
		setSelectedQuartile(quartile);
		if (selectedCutOptions?.city) {
			setSelectedCutOptions({
				address: selectedCutOptions.address,
				city: "ALL",
				zip: null,
				census_tract: null,
			});
		} else if (selectedCutOptions?.zip) {
			setSelectedCutOptions({
				address: selectedCutOptions.address,
				city: null,
				zip: "ALL",
				census_tract: null,
			});
		} else {
			setSelectedCutOptions({
				address: selectedCutOptions.address,
				city: null,
				zip: null,
				census_tract: "ALL",
			});
		}
		setHighlightedGeos([]);
		setDataLoading(true);
		let hGeos = [];
		try {
			const geo = determineGeo(selectedCutOptions);
			const response = await getBaselayerData(
				CURRENT_YEAR,
				geo,
				selectedBaseLayer.layer_type,
				selectedBaseLayer.content_key
			);
			const data = response.data;
			if (data.length > 0) {
				let start = 0;
				let end = Math.floor(data.length * 0.25);
				if (quartile.id == "second_top") {
					start = Math.floor(data.length * 0.25);
					end = Math.floor(data.length * 0.5);
				} else if (quartile.id == "second_bottom") {
					start = Math.floor(data.length * 0.5);
					end = Math.floor(data.length * 0.75);
				} else if (quartile.id == "bottom") {
					start = Math.floor(data.length * 0.75);
					end = data.length;
				}
				for (let a = start; a < end; a++) {
					hGeos.push(data[a].geoId.toString());
				}
			}
			setDataLoading(false);
		} catch (e) {
			console.error(e);
			setDataLoading(false);
		}
		setHighlightedGeos(hGeos);
	};

	const quartileCleared = async () => {
		setSelectedQuartile(null);
		if (selectedCutOptions?.city) {
			setSelectedCutOptions({
				address: null,
				city: "ALL",
				zip: null,
				census_tract: null,
			});
		} else if (selectedCutOptions?.zip) {
			setSelectedCutOptions({
				address: null,
				city: null,
				zip: "ALL",
				census_tract: null,
			});
		} else {
			setSelectedCutOptions({
				address: null,
				city: null,
				zip: null,
				census_tract: "ALL",
			});
		}
		setShowSearchClear(false);
		setHighlightedGeos([]);
		setPlaceMarker(null);
	};

	return (
		<div>
			<div className="logo-top-container">
				<div className="logo">
					<img
						className="advoc-brand-48"
						src="./assets/images/AdvanceOC_logo_color_48.png"
					/>
				</div>
				<div className="filter-options-toggle">
					<img
						src="./assets/images/filter2.png"
						onClick={filterToggleClick}
					/>
				</div>
			</div>
			<div className="filter-options" id="filterOptions">
				<div>
					<div
						className="cut-option large"
						style={{ marginLeft: "0" }}
					>
						<div className="select-title">
							{t("search_address")}
						</div>
						<div className="location-search">
							<LocationSearchInput
								value={selectedCutOptions.address}
								onSelect={(value, newPlaceId) => {
									if (
										value?.length > 0 &&
										newPlaceId?.length > 0
									) {
										placeChanged(value, newPlaceId);
									}
								}}
								onChange={(value) => {
									if (value) setShowSearchClear(true);
									else setShowSearchClear(false);
								}}
							/>
							{showSearchClear && (
								<div
									className="clear-icon"
									onClick={clearInputClick}
								>
									<img src="./assets/images/close.png" />
								</div>
							)}
						</div>
					</div>
					<CutOptions
						selectedOptions={selectedCutOptions}
						onChange={cutOptionChanged}
						onClear={clearCutOptions}
					></CutOptions>
					<div className="cut-option">
						<DropdownLanguages></DropdownLanguages>
					</div>
					<div style={{ clear: "both" }}></div>
				</div>
				<div style={{ marginTop: "8px" }}>
					<div style={{ float: "left" }}>
						<BaseLayersOptions
							selectedLayers={[selectedBaseLayer]}
							setSelectedLayers={setSelectedBaseLayer}
							onChange={layerOptionChanged}
							selectedQuartile={selectedQuartile}
							onQuartileChange={quartileOptionChanged}
							onQuartileClear={quartileCleared}
							selectedCutOptions={selectedCutOptions}
							setSelectedCutOptions={setSelectedCutOptions}
						></BaseLayersOptions>
					</div>
					<div
						style={{
							float: "right",
							marginTop: "17px",
							paddingRight: "0",
						}}
					>
						{!selectedCutGeo && (
							<>
								<div className="summary-scorecard-action">
									{t("summary")}
								</div>
								<div className="summary-scorecard-action">
									{t("scorecard")}
								</div>
							</>
						)}
						{selectedCutGeo && highlightedGeos?.length === 1 && (
							<>
								<div
									className="summary-scorecard-action active"
									onClick={summaryActionClick}
								>
									{t("summary")}
								</div>
								<div
									className="summary-scorecard-action active"
									onClick={scorecardActionClick}
								>
									{t("scorecard")}
								</div>
							</>
						)}
					</div>
					<div style={{ clear: "both" }}></div>
				</div>
				<div style={{ marginTop: "8px" }}>
					<div
						style={{ float: "left" }}
						className="overlays-options-container"
					>
						<OverlaysOptions
							selectedOverlays={[selectedOverlay]}
							onChange={overlayOptionChanged}
						></OverlaysOptions>
					</div>
					<div
						style={{
							display: "flex",
							alignItems: "center",
							columnGap: "1rem",
							float: "right",
							marginTop: "17px",
							paddingRight: "0",
						}}
					>
						{selectedQuartile?.title ? (
							<MapInfo text="With a quartile selected the colors are adjusted to more clearly show the ranking of census tracts within the selected quartile." />
						) : (
							<MapInfoBox text={t("best_results_popup")} />
						)}
						<button
							className="clear-all-filters"
							onClick={clearAllFilters}
						>
							{t("clear_all_filters")}
						</button>
					</div>
					<div style={{ clear: "both" }}></div>
				</div>
			</div>
			<div style={{ clear: "both" }}></div>
			<div
				className="map-container"
				style={{
					width:
						selectedGeo && selectedGeo.isScoreLoaded
							? "calc(100% - 660px)"
							: "100%",
					height: "550px",
					backgroundColor: "#eaeaea",
				}}
			>
				<MapBoxMap
					selected={selectedGeo}
					onSelect={mapClicked}
					cut={mapCut}
					baseLayer={selectedBaseLayer}
					overlay={selectedOverlay}
					highlightedGeos={highlightedGeos}
					selectedQuartile={selectedQuartile}
					selectedCutGeo={selectedCutGeo}
					placeMarker={placeMarker}
					reset={reset}
					setReset={setReset}
					selectedCutOptions={selectedCutOptions}
				/>
				{dataLoading && <Loading></Loading>}
			</div>

			{selectedGeo && (
				<ScoreCardContainer
					selectedGeo={selectedGeo}
					partial={scorecardView.partial}
					printed={scorecardView.printed}
					onCloseClick={closeScoreCardClick}
					onClickSimilar={similarLabelClick}
					onClick={() => {
						if (scorecardView.partial) {
							setScorecardView({
								partial: false,
								printed: false,
							});
						} else {
							setScorecardView({ partial: false, printed: true });
						}
					}}
					onScorecardLoaded={(sc) => {
						if (selectedGeo) {
							let sg = JSON.parse(JSON.stringify(selectedGeo));
							sg.isScoreLoaded = true;
							setSelectedGeo(sg);
						}
					}}
					setScorecardView={setScorecardView}
					selectedCutOptions={selectedCutOptions}
				/>
			)}

			{dataLoading && <LoadingLayover></LoadingLayover>}
		</div>
	);
};

export default MapTool;
