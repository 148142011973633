import { useTranslation } from "react-i18next";

type BaseLayerTooltipProps = {
	geoId: any;
	layerData: any;
	demographicsData: any;
};

export const BaseLayerTooltip = (props: BaseLayerTooltipProps) => {
	const { t } = useTranslation();
	let layerData = props.layerData;
	let geoId = props.geoId;
	let demographicsData = props.demographicsData;
	let keys = [
		"totpop",
		"mhi",
		"white",
		"black",
		"asian",
		"hispanicpop",
		"hawnpi",
		"indian",
		"under19",
		"age20_59",
		"over60",
		"foreignborn",
		"poor",
		// "below200pov",
		"disabilities",
		"hearvisdiff",
		"cogdiff",
		"ambdiff",
		"indlivdiff",
	];
	let popKey = keys.find((val) => val === "totpop");

	const addCommasToNumber = (number) => {
		if (!number) return;
		let numString = number.toString();
		numString = numString.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		return numString;
	};

	return (
		<>
			<table>
				<tbody>
					<tr key={1}>
						<td>{t("census_tract")}</td>
						<td style={{ textAlign: "right" }}>
							<b>{geoId}</b>
						</td>
					</tr>
					<tr key={2}>
						<td>{t("base_layer")}</td>
						<td style={{ textAlign: "right" }}>
							{parseFloat(layerData.content.value).toFixed(2)}
						</td>
					</tr>
					<tr key={3}>
						<td colSpan={2}>&nbsp;</td>
					</tr>
					{popKey && (
						<tr key="baselayer_demograhics_totpop">
							<td>{t("baselayer_demographics_totpop")}</td>
							<td style={{ textAlign: "right" }}>
								{addCommasToNumber(demographicsData[popKey])}
							</td>
						</tr>
					)}
					{keys.map((k) => {
						if (k === "totpop") {
							return null;
						}
						let append = "%";
						let tKey = "baselayer_demographics_" + k;
						let tVal = demographicsData[k]
							? demographicsData[k]
							: 0;
						if (tVal && k !== "mhi" && !isNaN(tVal)) {
							try {
								tVal = 100 * parseFloat(tVal);
							} catch (e) {}
						}
						if (k === "mhi") {
							return (
								<tr key={tKey}>
									<td>{t("baselayer_spi_mhi")}</td>
									<td style={{ textAlign: "right" }}>
										{demographicsData?.["mhi"]
											? `$${addCommasToNumber(
													parseFloat(
														demographicsData["mhi"]
															?.toString()
															?.replace(/,/g, "")
													)?.toFixed(0)
											  )}`
											: `$${addCommasToNumber(
													parseFloat(tVal)?.toFixed(0)
											  )}`}
									</td>
								</tr>
							);
						}
						return (
							<tr key={tKey}>
								<td>{t(tKey)}</td>
								<td style={{ textAlign: "right" }}>
									{parseFloat(tVal)?.toFixed(2)}
									{k !== "mhi" && append}
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		</>
	);
};
